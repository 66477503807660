import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';


// add Listeners on document ready
document.addEventListener('DOMContentLoaded', function() {
	changeHeaderColor();


	// This place for document ready

})

// All listeners on load
window.addEventListener('load', function(e) {
	addAnimationOnLoad(e)
	replaceLanguageOnMobile(e)
})

window.addEventListener('resize', function(e) {
	replaceLanguageOnMobile(e)

	if(window.innerWidth > 768) {
		let header = document.querySelectorAll('header a').forEach((el) => {
			el.classList.add('.uk-animation-slide-top-medium')
		});
	}
})

function addAnimationOnLoad(){

	let header = document.querySelector('header');
	let langSwitch = document.querySelector('.language-switch');
	let bigLetter = document.querySelector('.bit-letter');
	let pager = document.querySelector('.pager');

	UIkit.scrollspy(header, {
		target: 'a',
		cls: 'uk-animation-slide-top-medium',
		delay: 250
	});

	if (bigLetter) {
		UIkit.scrollspy(bigLetter, {
			cls: 'uk-animation-scale-up'
		});
	}

	if (pager) {
		UIkit.scrollspy(pager, {
			target: 'li',
			cls: 'uk-animation-slide-bottom-medium',
			delay: 350
		});
	}
}

function changeHeaderColor (e) {
	if (!document.querySelector('.banner__main')) {
		document.querySelector('header').classList.add('black');
		document.querySelector('header .logo').src = 'img/logo_black.svg';
	}
}


function replaceLanguageOnMobile(e) {
	let lang = document.querySelector('.lang');
	let nav = document.querySelector('header nav');

	if (window.innerWidth < 768) {
		nav.appendChild(lang)
	} else {

		nav.parentNode.appendChild(lang)
	}
}

